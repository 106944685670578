import {
    ACTION_VENUELIST_SUCCESS,
    ACTION_EVENTS_SUCCESS
} from './action';
import createReducer from '../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const EventSearchReducer = createReducer(
    INITIAL_STATE,
    {

        [ACTION_VENUELIST_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_VENUELIST_SUCCESS,
                loading: false
            });
        },
        [ACTION_EVENTS_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: ACTION_EVENTS_SUCCESS,
                loading: false
            });
        },
    }
);
export default EventSearchReducer;