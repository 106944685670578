var localStorage = window.localStorage;

export const KEY_USER_TOKEN = "user_token";
export const KEY_USER = "user";
export const USER_TYPE = "user_type";
export const EVENTDATA = 'event_data';

/**
 *
 * @param {venu section} key
 * @param {*} data
 */
export const setData = (key, data) => {
	try {
		localStorage.setItem(key, data);
	} catch (error) {
		// Error saving data
	}
};

export const getData = (key) => {
	try {
		const value = localStorage.getItem(key);
		if (value !== null) {
			return value;
		}
	} catch (error) {
		return null;
	}
	return null;
};

export const removeData = (key) => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		// Error while removing
	}
};

export const clearData = () => {
	try {
		localStorage.clear();
	} catch (error) {
		// Error while removing
	}
};
