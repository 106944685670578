import React from 'react';
import { Route, Switch } from 'react-router';
import CustomerDetail from './deatil';



const CustomerRoutes = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={CustomerDetail} />
        </Switch>
    )
}

export default CustomerRoutes