import React from "react";
import { Route, Switch } from "react-router-dom";
import { withAuth } from "react-auth-guard";
import EventsRoutes from '../../../src/routes/app/auth/events';
import ScanRoutes from "../../routes/app/auth/scan";
import ScanQRCodeResultRoutes from '../../routes/app/auth/scan_qr_code_result';
import CustomerRoutes from "../../routes/app/auth/customer";

const Content = (props) => {
        return (
                <div className="app-content bg-background">
                        <div className="content-overlay"></div>
                        <div
                                id="progress-loader"
                                className="hidden"
                                style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                }}
                        >
                                <div
                                        className="blockUI blockOverlay"
                                        style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                backgroundColor: "#FFFFFF",
                                                opacity: 0.6,
                                        }}
                                ></div>
                                <div
                                        className="spinner-border text-dark"
                                        role="status"
                                        style={{
                                                position: "absolute",
                                                left: "50%",
                                                top: "3%",
                                                right: "10%",
                                                zIndex: 100,
                                        }}
                                >
                                        <span className="sr-only">
                                                Loading...
                                        </span>
                                </div>
                        </div>
                        <div className="content-wrapper">
                                <Switch>

                                        <Route path="/events" component={EventsRoutes} />
                                        <Route path="/scan-qr-code" component={ScanRoutes} />
                                        <Route path="/scan-qr-code-result" component={ScanQRCodeResultRoutes} />
                                        <Route path="/customer-details" component={CustomerRoutes} />

                                </Switch>
                        </div>
                </div>
        );
};

export default withAuth(Content);
