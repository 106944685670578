import {
ACTION_LOGIN_SUCCESS
} from './action';
import createReducer from '../../../../reducers/createReducer';

const INITIAL_STATE = {
        data: [],
        loading: false
    };

const VenueLoginReducer = createReducer(
	INITIAL_STATE,
	{
		[ACTION_LOGIN_SUCCESS](state, action) {
                        return Object.assign({}, {
                            ...state,
                            data: action.payload.data,
                            tag: ACTION_LOGIN_SUCCESS,
                            loading: false
                        });
                    }
	}
);
export default VenueLoginReducer;