/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import { getData, KEY_USER_TOKEN } from "../utils/preferences";
import { PRINT } from "../utils";


class Api {
  static async headers() {

    const token = getData(KEY_USER_TOKEN);
    if (token != null) {
      return {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        authorization: `Token ${token}`,
      };
    }

    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
  }

  static get(route) {
    return this.xhr(route, null, "GET");
  }

  static put(route, params) {
    return this.xhr(route, params, "PUT");
  }

  static post(route, params) {
    return this.xhr(route, params, "POST");
  }

  static delete(route, params) {
    return this.xhr(route, params, "DELETE");
  }

  static async xhr(route, params, verb) {
    const url = `${route}`;
    PRINT("API", url);
    PRINT("PARAMS", params);
    const options = Object.assign(
      { method: verb },
      params ? { body: JSON.stringify(params) } : null
    );
    options.headers = await Api.headers();
    PRINT("HEADERS", options.headers);
    return fetch(url, options).then((resp) => {
      const json = resp.json();
      if (resp.ok) {
        PRINT("RESPOSNE", json);
        return json;
      }
      return json.then((err) => {
        PRINT("ERROR", err);
        throw err;
      });
    });
  }

  static async multipart(route, params, files) {
    const url = `${route}`;
    PRINT("API", url);
    PRINT("PARAMS", params);
    const options = Object.assign({ method: "POST" });
    const token = await getData(KEY_TOKEN);
    if (token != null) {
      options.headers = {
        authorization: `Token ${token}`,
      };
    }
    const formData = new FormData();
    if (params != null) {
      for (const key in params) {
        PRINT("KEY", key);
        PRINT("VALUE", params[key]);
        if (params[key] instanceof Object || params[key] instanceof Array) {
          formData.append(key, JSON.stringify(params[key]));
        } else {
          formData.append(key, params[key]);
        }
      }
    }
    if (files != null && files.length > 0) {
      PRINT("VALUE", files);
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append(file.name, file.value);
      }
    }
    options.body = formData;
    PRINT("HEADERS", options.headers);
    return fetch(url, options).then((resp) => {
      const json = resp.json();
      if (resp.ok) {
        PRINT("RESPOSNE", json);
        return json;
      }
      return json.then((err) => {
        PRINT("ERROR", err);
        throw err;
      });
    });
  }
}
export default Api;
