import React from 'react';
import { Route, Switch } from 'react-router';
import ScanQRCodeResult from './list';



const ScanQRCodeResultRoutes = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={ScanQRCodeResult} />
        </Switch>
    )
}

export default ScanQRCodeResultRoutes