import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withAuth } from "react-auth-guard";
import NotFound from "./not_found";
import Login from "./login";

const PublicRoute = (props) => {
    const { match, location, history } = props;

    if (location.pathname === "/") {
        window.location.replace("/login");
    }
    return (
        <Router history={history}>
            <>
                {/* <div className="container"> */}
                <Switch>
                    <Route
                        exact
                        path={`${match.url}login`}
                        component={Login}
                        {...props}
                    />
                    <Route
                        path={`${match.url}`}
                        component={NotFound}
                    />
                </Switch>
                {/* </div> */}
            </>
        </Router>
    );
};
export default withAuth(PublicRoute);
