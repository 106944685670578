import React, { useEffect } from 'react';

const NotFound = (props) => {

    useEffect(() => {
        window.location.replace("/login");
    }, [])

    return (
        <>
           
        </>
    )
}

export default NotFound;
