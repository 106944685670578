import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_TICKET_VERIFY } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';


export const TICKET_VERIFY_LOADING = 'TICKET_VERIFY_LOADING';
export const TICKET_VERIFY_SUCCESS = 'TICKET_VERIFY_SUCCESS';


export function actionTicketVerify(params) {
    return (dispatch, getState) => Api.post(API_TICKET_VERIFY, params).then((res) => {
        const data = res;
        PRINT('TICKET VERIFY RESPONSE : ', data);
        dispatch(fetchSuccess(TICKET_VERIFY_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(TICKET_VERIFY_LOADING, { loading: false, data }));
    });
}

