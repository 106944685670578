export const HOST =
        process.env.NODE_ENV === "production"
                ? "https://api.hazelgl.com"
                : "https://api.hazelgl.com";
export const IMAGE_PATH =
        process.env.NODE_ENV === "production"
                ? ""
                : "";

const BASE_URL = HOST;

// PREFIX
const EVENT_PREFIX = "/event/";

export const API_LOGIN = BASE_URL + EVENT_PREFIX + "event/login";

export const API_VENUELIST = BASE_URL + EVENT_PREFIX + "event/venue-list";
export const API_EVENTS = BASE_URL + EVENT_PREFIX + "event/event-list";

export const API_TICKET_PURCHASE_LIST = BASE_URL + EVENT_PREFIX + "event/ticket-purchased-list";

export const API_TICKET_PURCHASE_DETAIL = BASE_URL + EVENT_PREFIX + "event/ticket-purchased-detail";

export const API_TICKET_VERIFY = BASE_URL + EVENT_PREFIX + "event/ticket_verify";




