import React from 'react';
import { Route, Switch } from 'react-router';
import Events from './list';



const EventsRoutes = (props) => {
    const { match } = props
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={Events} />
        </Switch>
    )
}

export default EventsRoutes