import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import $ from 'jquery';
import { VscCalendar } from 'react-icons/vsc';
import { GoLocation } from 'react-icons/go';
import { FaTag } from 'react-icons/fa';
import { AiFillCheckCircle, AiFillCloseCircle,AiOutlineMessage } from 'react-icons/ai';
import { FaUser, FaExclamationTriangle } from 'react-icons/fa';
import {ImSpoonKnife} from 'react-icons/im'
import dateFormat from 'dateformat';
import { EVENTDATA, getData } from "../../../../../utils/preferences";

const ScanQRCodeResult = (props) => {



    const [eventdetails, setEventDetails] = useState(null);

    const initialRender = () => {
        $("body").addClass("bg-background");
        setEventDetails(props.location.state.result)


    };
    useEffect(initialRender, []);


    return (
        <>
            <div className="container login-container">
                <section id="basic-form-layouts" style={{ padding: '1.5rem' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card box-container">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-2">
                                                <VscCalendar color="#051fde" size={20} />
                                            </div>
                                            <div className="col-10">
                                                <p class="font-medium-2 text-color" >{dateFormat(props.location.state.date, 'dd mmmm yyyy')}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2">
                                                <GoLocation color="#051fde" size={20} />
                                            </div>
                                            <div className="col-10">
                                                <p class="font-medium-2 text-color" >{props.location.state.eventname}</p>
                                            </div>
                                        </div>
                                        {
                                            props.location.state.venuetitle != '' ?
                                                <div className="row">
                                                    <div className="col-2">
                                                        <FaTag color="#051fde" size={20} />
                                                    </div>
                                                    <div className="col-10">
                                                        <p class="font-medium-2 text-color" >{props.location.state.venuetitle}</p>
                                                    </div>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center align-items-center">
                                {eventdetails != null && eventdetails.message.includes("Valid Ticket") ?
                                    <AiFillCheckCircle color="green" size={140} />
                                    : eventdetails != null && eventdetails.message.includes("Ticket Already Used!") ?
                                        <FaExclamationTriangle color="#FFD700" size={140} /> :
                                        eventdetails != null && eventdetails.message.includes("Invalid Ticket") ?
                                            <AiFillCloseCircle color="red" size={140} /> : ''

                                }
                            </div>
                            <div className="row justify-content-center align-items-center mb-2">
                                <h2 className="text-color">{eventdetails != null ? eventdetails.message : ''}</h2>
                            </div>
                            {
                                eventdetails != null && eventdetails.hasOwnProperty('purchaser_name') && eventdetails.purchaser_name != '' ?
                                    <div className="row">
                                        <div className="col-2">
                                            <FaUser color="#051fde" size={20} />
                                        </div>
                                        <div className="col-10">
                                            <p class="font-medium-2 text-color" >
                                                {eventdetails != null ? eventdetails.purchaser_name : ''}
                                            </p>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                eventdetails != null && eventdetails.hasOwnProperty('ticket_title') && eventdetails.ticket_title != '' ?
                                    <div className="row">
                                        <div className="col-2">
                                            <FaTag color="#051fde" size={20} />
                                        </div>
                                        <div className="col-10">
                                            <p class="font-medium-2 text-color" > {eventdetails != null ? eventdetails.ticket_title : ''}</p>
                                        </div>
                                    </div>
                                    : <></>}
                            {
                                eventdetails != null && eventdetails.hasOwnProperty('dropdown_value') && eventdetails.dropdown_value != '' ?
                                    <div className="row">
                                        <div className="col-2">
                                            <ImSpoonKnife color="#051fde" size={20} />
                                        </div>

                                        <div className="col-10">
                                            <p class="font-medium-2 text-color" >{eventdetails.dropdown_value}</p>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                eventdetails != null && eventdetails.hasOwnProperty('text_input_value') && eventdetails.text_input_value != '' ?
                                    <div className="row">
                                        <div className="col-2">
                                            <AiOutlineMessage color="#051fde" size={20} />
                                        </div>
                                        <div className="col-10">
                                            <p class="font-medium-2 text-color" >{eventdetails.text_input_value}</p>
                                        </div>
                                    </div>
                                    : <></>}
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}


export default withAuth(ScanQRCodeResult)