// import { Html5QrcodeScanner } from "html5-qrcode";
// import React from 'react';

// const qrcodeRegionId = "html5qr-code-full-region";

// class Html5QrcodePlugin extends React.Component {

//     render() {
//         return <div id={qrcodeRegionId} />;
//     }

//     componentWillUnmount() {
//         // TODO(mebjas): See if there is a better way to handle
//         //  promise in `componentWillUnmount`.
//         this.html5QrcodeScanner.clear().catch(error => {
//             console.error("Failed to clear html5QrcodeScanner. ", error);
//         });
//     }

//     componentDidMount() {

//         // Creates the configuration object for Html5QrcodeScanner.
//         function createConfig(props) {
//             var config = {};
//             if (props.fps) {
//                 config.fps = props.fps;
//             }
//             if (props.qrbox) {
//                 config.qrbox = props.qrbox;
//             }
//             if (props.aspectRatio) {
//                 config.aspectRatio = props.aspectRatio;
//             }
//             if (props.disableFlip !== undefined) {
//                 config.disableFlip = props.disableFlip;
//             }
//             return config;
//         }

//         var config = createConfig(this.props);
//         var verbose = this.props.verbose === true;

//         // Suceess callback is required.
//         if (!(this.props.qrCodeSuccessCallback)) {
//             throw "qrCodeSuccessCallback is required callback.";
//         }

//         //this.html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
//         this.html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, {
//             fps: 10,
//             qrbox: { width: 250, height: 250 },
//             videoConstraints: { facingMode: { exact: "environment" } },
//             disableFlip: false,

//         });

//         this.html5QrcodeScanner.render(this.props.qrCodeSuccessCallback, this.props.qrCodeErrorCallback);
//     }
// };

// export default Html5QrcodePlugin;

import React, { useState, useEffect } from 'react'
import QrReader from 'modern-react-qr-reader'
import Swal from "sweetalert2";
import { PRIMARY_COLOR } from '../../../../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionTicketVerify, TICKET_VERIFY_SUCCESS } from '../list/action';
import { withAuth } from 'react-auth-guard';
import { clearData } from '../../../../../utils/preferences';


const QrcodeScanner = (props) => {


    const [QRCodeScan, setQRCodeScan] = useState(true)


    const onTicketVerifyPurchaseUpdate = () => {

        const { TicketScanVerify } = props;

        if (TicketScanVerify != null) {
            var TicketScanVerifyResponse = Object.assign({}, TicketScanVerify);
            if (TicketScanVerifyResponse.result === true) {
                props.history.push({
                    pathname: `/scan-qr-code-result`, state: {
                        result: TicketScanVerifyResponse.response,
                        date: props.location.state.eventdate,
                        venuetitle: props.venuetitle,
                        eventname: props.eventname
                    }
                })
            } else {
                switch (TicketScanVerifyResponse.status) {
                    case 514:
                        props.history.push({
                            pathname: `/scan-qr-code-result`, state: {
                                result: TicketScanVerifyResponse.response,
                                date: props.location.state.eventdate,
                                venuetitle: props.venuetitle,
                                eventname: props.eventname
                            }
                        })
                        break;
                    case 513:
                        props.history.push({
                            pathname: `/scan-qr-code-result`, state: {
                                result: TicketScanVerifyResponse,
                                date: props.location.state.eventdate,
                                venuetitle: props.venuetitle,
                                eventname: props.eventname
                            }
                        })
                        break;
                    case 400:
                        clearData();
                        props.auth.updateToken(null);
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }


    useEffect(onTicketVerifyPurchaseUpdate, [props.TicketScanVerify]);

    const handleScan = data => {

        if (data != null) {
            var result = data
            var finalresult = result.split('-');
            var id = finalresult[0]
            var venue_id = finalresult[1]
            var event_id = finalresult[2]
            var ticket_id = finalresult[3]
            var ticket_uid = finalresult[4]
            if (QRCodeScan === true) {
                setQRCodeScan(false)
                props.actionTicketVerify({
                    id: id,
                    venue_id: venue_id,
                    event_id: event_id,
                    ticket_id: ticket_id,
                    ticket_uid: ticket_uid
                })
            }


            // Swal.fire({
            //     title: "QR Code Scan",
            //     text: `${data}`,
            //     icon: "success",
            //     // confirmButtonText: `Yes`,
            //     // denyButtonText: `No`,
            //     // showDenyButton: true,
            //     confirmButtonColor: PRIMARY_COLOR
            // }).then((success) => {
            //     if (success.isConfirmed) {
            //         this.props.history.push({
            //             pathname: `/scan-qr-code-result`, state: {
            //                 result: data,
            //                 date: this.props.location.state.eventdate,
            //                 venuetitle: this.props.venuetitle
            //             }
            //         });

            //     }
            // });

        }



    }

    const handleError = err => {
        console.error(err)
    }

    return (
        <div>
            <QrReader
                delay={300}
                facingMode={"environment"}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}
            />

        </div>
    )
}

function mapStateToProps(state) {

    var TicketScanVerify = null;

    if (
        state.TickeScanVerifyReducer != null &&
        Object.keys(state.TickeScanVerifyReducer).length > 0 &&
        state.TickeScanVerifyReducer.constructor === Object
    ) {
        switch (state.TickeScanVerifyReducer.tag) {
            case TICKET_VERIFY_SUCCESS:
                TicketScanVerify = Object.assign({}, state.TickeScanVerifyReducer.data);
                delete state.TickeScanVerifyReducer;
                return { TicketScanVerify };
            default:
                return { TicketScanVerify }
        }
    }
    return { TicketScanVerify }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionTicketVerify }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(QrcodeScanner));