import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_VENUELIST,API_EVENTS } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const VENUELIST_LOADING = 'VENUELIST_LOADING';
export const ACTION_VENUELIST_SUCCESS = 'ACTION_VENUELIST_SUCCESS';

export const EVENTS_LOADING = 'EVENTS_LOADING';
export const ACTION_EVENTS_SUCCESS = 'ACTION_EVENTS_SUCCESS';


export function actionVenueList() {
    return (dispatch, getState) => Api.get(API_VENUELIST).then((res) => {
        const data = res;
        PRINT('VENUELIST ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(ACTION_VENUELIST_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(VENUELIST_LOADING, { loading: false, data }));
    });
}



export function actionEvents(params) {
        return (dispatch, getState) => Api.post(API_EVENTS,params).then((res) => {
            const data = res;
            PRINT('EVENTS ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_EVENTS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(EVENTS_LOADING, { loading: false, data }));
        });
    }