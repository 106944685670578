/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { AiOutlineMenu } from 'react-icons/ai';
import { BiPowerOff } from 'react-icons/bi';
import { FiBell, FiPower, FiUser, FiX } from 'react-icons/fi';
import { clearData, getData, USER_TYPE } from '../../utils/preferences';
import { withAuth } from 'react-auth-guard';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import $ from 'jquery';

const Header = (props) => {
    const { match } = props;

    const [usertype, setUserType] = useState('')

    useEffect(() => {
        if (props.history.location.pathname != "/events") {
            $('#backarrow').removeClass('hidden')
            $('#backarrow-md').removeClass('hidden')
        } else {
            $('#backarrow').addClass('hidden')
            $('#backarrow-md').addClass('hidden')
        }
    }, [props])

    useEffect(() => {
        var userType = getData(USER_TYPE);
        if (userType != null) {
            setUserType(JSON.parse(userType))
        }
    }, [usertype])


    return (
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top  bg-gradient-x-grey-blue">
            <div className="navbar-wrapper">
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        <li className="nav-item mobile-menu d-md-none mr-auto">
                            <a
                                className="nav-link nav-menu-main hidden-xs"
                                onClick={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                <IoIosArrowBack
                                    id='backarrow'
                                    className='hidden'
                                    size={20}
                                    color="white"
                                    onClick={(e) => {
                                        props.history.goBack()
                                    }}
                                />
                                <img
                                    className="brand-logo"
                                    style={{ width: 65, height: 30, padding: 0, objectFit: 'contain' }}
                                    alt="logo"
                                    src="/assets/images/hazel.png"
                                />
                            </a>
                        </li>
                        <li className='nav-item mobile-menu d-md-none mr-auto' style={{
                            marginLeft: `${props.history.location.pathname != "/events" ? '-140px' : '-160px'}`
                        }}>
                            <h2 className="text-center text-color nav-link nav-menu-main hidden-xs">Events</h2>
                        </li>
                        <li className="nav-item d-md-none">
                            <a
                                className="nav-link open-navbar-container"
                                data-toggle="collapse"
                                data-target="#navbar-mobile"
                            >
                                <BiPowerOff color='white' size={24} />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="navbar-container content bg-background" style={{ marginLeft: 0 }}>
                    <div
                        class="collapse navbar-collapse"
                        id="navbar-mobile"
                    >
                        <a
                            className="nav-link nav-menu-main d-none d-md-block d-lg-block"
                            onClick={(e) => {
                                e.preventDefault()
                            }}
                        >
                            <IoIosArrowBack
                                id='backarrow-md'
                                className='hidden'
                                size={20}
                                color="white"
                                onClick={(e) => {
                                    props.history.goBack()
                                }}
                            />
                            <img
                                className="brand-logo"
                                style={{ width: 100, height: 40, padding: 3, objectFit: 'contain' }}
                                alt="logo"
                                src="/assets/images/hazelgl.png"
                            />
                            <span className="text-center text-color font-medium-3">Events</span>
                        </a>
                        <ul class="nav navbar-nav float-left ml-auto">

                        </ul>
                        <ul class="nav navbar-nav float-right">
                            <li class="dropdown dropdown-user nav-item">
                                <a
                                    class="dropdown-toggle nav-link dropdown-user-link"
                                    href="#"
                                    data-toggle="dropdown"
                                >
                                    {/* Profile Photo if required */}
                                    <span class="text-color">{`${usertype === "A" ? props.auth.getUser().username : props.auth.getUser().email}`}</span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right bg-background">
                                    <a
                                        class="dropdown-item text-color"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            clearData();
                                            props.auth.updateToken(null);
                                            props.auth.logout();
                                        }}
                                    >
                                        <FiPower className="mr-1" size={16} />
                                        Logout
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>


    )
}

export default withAuth(Header);