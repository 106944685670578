import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionVenueList, ACTION_VENUELIST_SUCCESS } from './action';
import { actionEvents, ACTION_EVENTS_SUCCESS } from './action';
import { useForm, Controller } from "react-hook-form";
import DateBox from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import $ from 'jquery';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { AiOutlineDown } from 'react-icons/ai';
import { clearData, EVENTDATA, getData, setData, USER_TYPE } from "../../../../../utils/preferences";

import flatpickr from "flatpickr";
import { FaCaretDown } from "react-icons/fa";
import { AiFillCalendar } from 'react-icons/ai';


const Events = (props) => {

    const { match } = props;

    const [eventdate, seteventDate] = useState(dateFormat(new Date(), 'yyyy-mm-dd'))
    const [venueid, setVenueId] = useState('')
    const [eventid, setEventId] = useState('')


    const [venues, setVenues] = useState([])
    const [events, setEvents] = useState([])
    const [usertype, setUserType] = useState('')

    // const [selectedDate, setSelectedDate] = useState(dateFormat(new Date(), 'yyyy-mm-dd'));

    const initialRender = () => {
        $("body").addClass("bg-background");

        props.actionVenueList()

        var userType = getData(USER_TYPE);
        if (userType != null) {
            setUserType(JSON.parse(userType))
        }
        flatpickr("#date-picker", {
            dateFormat: "d M Y",
            defaultDate: new Date(),
            minDate: new Date(),
            enableTime: false,
            disableMobile: true,

            onChange: (selectedDate, dateStr, instance) => {
                seteventDate(dateFormat(selectedDate, "yyyy-mm-dd"));
            },
        });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (eventdate != '' && venueid != '') {
            props.actionEvents({
                venue_id: venueid,
                event_date: eventdate
            })
        }

    }, [eventdate, venueid])

    useEffect(() => {
        if (usertype === "V") {
            props.actionEvents({
                event_date: eventdate
            })
        }

    }, [eventdate, usertype])


    const onVenueListpdate = () => {

        const { VenueList } = props;

        if (VenueList != null) {
            var VenueListResponse = Object.assign({}, VenueList);
            if (VenueListResponse.result === true) {
                setVenues(VenueListResponse.response);
            } else {
                switch (VenueListResponse.status) {
                    case 400:
                        clearData();
                        props.auth.updateToken(null);
                        props.auth.logout();
                        break;
                    default:

                }
            }
        }
    }


    useEffect(onVenueListpdate, [props.VenueList]);

    const onEventsUpdate = () => {

        const { Events } = props;

        if (Events != null) {
            var EventsResponse = Object.assign({}, Events);
            if (EventsResponse.result === true) {
                setEvents(EventsResponse.response)
            } else {
                setEvents([])
                switch (EventsResponse.status) {
                    case 400:
                        clearData();
                        props.auth.updateToken(null);
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }


    useEffect(onEventsUpdate, [props.Events]);

    const generateVenues = () => {
        var menu = [];
        if (venues != null) {
            if (venues.length > 0) {
                venues.forEach(function (child, labelIndex) {
                    menu.push(
                        <option key={labelIndex} id={child.venue_id} value={child.venue_id} >
                            {child.venue_title}
                        </option>
                    );
                });
            }
        }
        return menu;
    };

    const generateEvents = () => {
        var menu = [];
        if (events != null) {
            if (events.length > 0) {
                events.forEach(function (child, labelIndex) {
                    menu.push(
                        <option key={labelIndex} id={child.id} value={child.id} >
                            {child.title}
                        </option>
                    );
                });
            }
        }
        return menu;
    };


    const onSubmit = (data) => {


        if (usertype === "A" && venueid === '') {
            $('#venue_error').removeClass('hidden')
        }
        else if (eventid === '') {
            $('#event_error').removeClass('hidden')
        } else {

            props.history.push({ pathname: `/scan-qr-code`, state: { event_id: eventid, venue_id: venueid, eventdate: eventdate } })
        }

    }

    return (
        <>
            <div className="container login-container bg-background" style={{ paddingTop: 0 }}>
                <div className="card mx-auto round py-1 bg-background">
                    <div className="card-body">
                        <form
                            className="form-content"
                            noValidate
                            autoComplete="off"
                        >
                            <div className="row justify-content-md-center mb-4">
                                <div className="col-md-8">
                                    <div className="form-group round">
                                        {/* <DateBox
                                            className="form-control"
                                            pickerType="calendar"
                                            type="date"
                                            defaultValue={new Date()}
                                            acceptCustomValue={false}
                                            displayFormat="dd MMMM yyyy"
                                            min={new Date()}
                                            onOpened={true}
                                            openOnFieldClick={true}
                                            // value={date}
                                            onValueChange={(value) => {
                                                seteventDate(dateFormat(value, 'yyyy-mm-dd'))
                                            }}
                                        /> */}
                                        <fieldset className="form-group position-relative has-icon-right">

                                            <input
                                                type="text"
                                                className="form-control text-black text-bold-700"
                                                id="date-picker"
                                                placeholder="Select Date"
                                                onChange={(event) => {
                                                    seteventDate(event.target.value);
                                                }}
                                                style={{
                                                    backgroundColor: 'black'
                                                }}
                                            />
                                            <div className="form-control-position" style={{
                                                pointerEvents: "none",
                                                cursor: "pointer",
                                            }}>
                                                <AiFillCalendar color="white" size={16} />
                                            </div>
                                        </fieldset>
                                    </div>
                                    {
                                        usertype === "A" ?
                                            <div className="form-group round">
                                                <fieldset className="form-group position-relative has-icon-right">
                                                    <select
                                                        type="text"
                                                        className="form-control"
                                                        id="venue_id"
                                                        placeholder="kkkk"
                                                        name="venue_id"
                                                        value={venueid}
                                                        onChange={(e) => {
                                                            $('#venue_error').addClass('hidden')
                                                            setVenueId(e.target.value)
                                                        }}

                                                    >
                                                        <option value="">Select Venue</option>
                                                        {generateVenues()}
                                                        <option value="0">Others</option>
                                                    </select>
                                                    <label
                                                        id="venue_error"
                                                        className="form-check-label font-small-2 text-danger hidden"
                                                        for="invalidCheck"

                                                    >
                                                        Please Select Venue
                                                    </label>
                                                    <div className="form-control-position">
                                                        <AiOutlineDown color="white" size={16} />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            :
                                            ''
                                    }


                                    <div className="form-group round">
                                        <fieldset className="form-group position-relative has-icon-right">
                                            <select
                                                type="text"
                                                className="form-control"
                                                id="event_id"
                                                name="event_id"
                                                value={eventid}
                                                onChange={(e) => {
                                                    $('#event_error').addClass('hidden')
                                                    setEventId(e.target.value)
                                                }}

                                            >
                                                <option value="">Select Event</option>
                                                {generateEvents()}
                                            </select>
                                            <label
                                                id="event_error"
                                                className="form-check-label font-small-2 text-danger hidden"
                                                for="invalidCheck"

                                            >
                                                Please Select Event
                                            </label>
                                            <div className="form-control-position">
                                                <AiOutlineDown color="white" size={16} />
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-8 col-md-6">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block rounded"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onSubmit()
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

function mapStateToProps(state) {

    var VenueList = null;
    var Events = null;

    if (
        state.EventSearchReducer != null &&
        Object.keys(state.EventSearchReducer).length > 0 &&
        state.EventSearchReducer.constructor === Object
    ) {
        switch (state.EventSearchReducer.tag) {
            case ACTION_VENUELIST_SUCCESS:
                VenueList = Object.assign({}, state.EventSearchReducer.data);
                delete state.EventSearchReducer;
                return { VenueList };
            case ACTION_EVENTS_SUCCESS:
                Events = Object.assign({}, state.EventSearchReducer.data);
                delete state.EventSearchReducer;
                return { Events };
            default:
                return { VenueList, Events }
        }
    }
    return { VenueList, Events }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionVenueList, actionEvents }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Events));
