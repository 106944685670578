import React, { useState, useEffect } from "react";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionLogin, ACTION_LOGIN_SUCCESS } from './action';
import $ from "jquery";
import { useForm } from "react-hook-form";
import {
        KEY_USER,
        KEY_USER_TOKEN,
        setData,
        USER_TYPE,
} from "../../../../utils/preferences";
import ErrorLabel from "../../../../component/error_label";
import Swal from 'sweetalert2';
import { PRIMARY_COLOR } from "../../../../constants";


const Login = (props) => {
        const {
                register,
                formState: { errors },
                handleSubmit,
        } = useForm({
                defaultValues: {
                        username: "",
                        password: "",
                },
        });


        const initialRender = () => {
                $("body").addClass("bg-background");
                $("body")
                        .removeClass("content-left-sidebar fixed-navbar")
                        .addClass("1-column")
                        // .css("background-image", "url('/assets/images/background.jpg')")
                        .css("background-attachment", "fixed")
                        .css("background-repeat", "no-repeat")
                        .css("background-size", "cover")
                        .css("background-position", "center")
                        .css("height", "100%");

                return () => {
                        $("body").addClass("content-left-sidebar fixed-navbar");
                        $("body").css("background-image", "none");
                };
        };
        useEffect(initialRender, []);

        const onLoginUpdate = () => {

                const { Login } = props;

                if (Login != null) {
                        var VenueLoginResponse = Object.assign({}, Login);
                        if (VenueLoginResponse.result === true) {
                                setData(KEY_USER, JSON.stringify(VenueLoginResponse.response.user));
                                setData(KEY_USER_TOKEN, VenueLoginResponse.response.token);
                                setData(USER_TYPE, JSON.stringify(VenueLoginResponse.response.user_type))
                                props.auth.updateToken(VenueLoginResponse.response.token);
                        } else {
                                switch (VenueLoginResponse.status) {
                                        default:
                                                Swal.fire({
                                                        title: "Message",
                                                        text: VenueLoginResponse.message,
                                                        icon: "info",
                                                        confirmButtonColor: PRIMARY_COLOR
                                                })
                                                break;

                                }
                        }
                }
        }


        useEffect(onLoginUpdate, [props.Login]);

        const onSubmit = async (data) => {
                props.actionLogin({
                        username: data.username,
                        password: data.password
                })

        };

        return (
                <>
                        <div className="container login-container bg-background">
                                <div className="card mx-auto round py-1 bg-background">
                                        <div className="card-header py-0 text-center bg-background mb-4">
                                                <img
                                                        src={
                                                                "/assets/images/hazel.png"
                                                        }
                                                        alt=""
                                                        width={250}
                                                        height={80}
                                                        style={{
                                                                padding: 8,
                                                                objectFit: 'contain',
                                                        }}
                                                />
                                                <h2 className="text-center text-color">
                                                        Events
                                                </h2>
                                        </div>
                                        <div className="card-body">
                                                <form
                                                        className="form-content"
                                                        noValidate
                                                        autoComplete="off"
                                                        onSubmit={handleSubmit(
                                                                onSubmit
                                                        )}
                                                >
                                                        <div className="row justify-content-md-center">
                                                                <div className="col-md-8">
                                                                        <div className="form-body mb-3">
                                                                                <div className="form-group round">
                                                                                        <input
                                                                                                className="form-control textfieldcenter"
                                                                                                type="text"
                                                                                                id="username"
                                                                                                name="username"
                                                                                                placeholder="username"
                                                                                                {...register(
                                                                                                        "username",
                                                                                                        {
                                                                                                                required: "Username is required",
                                                                                                                pattern: {
                                                                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                                                                        message: "Email in invalid",
                                                                                                                },
                                                                                                        }
                                                                                                )}
                                                                                        />
                                                                                        {errors.username && (
                                                                                                <ErrorLabel
                                                                                                        message={
                                                                                                                errors
                                                                                                                        .username
                                                                                                                        ?.message
                                                                                                        }
                                                                                                />
                                                                                        )}
                                                                                </div>
                                                                                <div className="form-group round">
                                                                                        <input
                                                                                                className="form-control textfieldcenter"
                                                                                                type="password"
                                                                                                id="password"
                                                                                                name="password"
                                                                                                placeholder="password"
                                                                                                {...register(
                                                                                                        "password",
                                                                                                        {
                                                                                                                required: "Password is required",
                                                                                                        }
                                                                                                )}
                                                                                        />
                                                                                        {errors.password && (
                                                                                                <ErrorLabel
                                                                                                        message={
                                                                                                                errors
                                                                                                                        .password
                                                                                                                        ?.message
                                                                                                        }
                                                                                                />
                                                                                        )}
                                                                                </div>
                                                                        </div>
                                                                        <div className="row justify-content-center align-items-center">
                                                                                <div className="col-8 col-md-8">
                                                                                        <button
                                                                                                type="submit"
                                                                                                className="btn btn-primary btn-block rounded"
                                                                                                onClick={() => {
                                                                                                        //setData(KEY_USER, JSON.stringify({ first_name: 'Mahesh', last_name: 'K' })); props.auth.updateToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4MDg4MjkiLCJuYW1lIjoiVHVzaGFyIiwiaWF0IjoxNjE5MjQ0MDAwfQ.39emaBEl06OxhpA2bWISdIArcs63IBTPf57ywN5W6zs')
                                                                                                }}
                                                                                        >
                                                                                                LOGIN
                                                                                        </button>
                                                                                </div>

                                                                        </div>
                                                                </div>
                                                        </div>
                                                </form>
                                        </div>
                                </div>
                        </div>

                </>
        );
};



function mapStateToProps(state) {

        var Login = null;

        if (
                state.LoginReducer != null &&
                Object.keys(state.LoginReducer).length > 0 &&
                state.LoginReducer.constructor === Object
        ) {
                switch (state.LoginReducer.tag) {

                        case ACTION_LOGIN_SUCCESS:
                                Login = Object.assign({}, state.LoginReducer.data);
                                delete state.LoginReducer;
                                return { Login };
                        default:
                                return { Login }
                }
        }
        return { Login }
}

function mapDispatchToProps(dispatch) {
        return bindActionCreators({ actionLogin }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Login));

