import Api from '../../../../api';
import { fetchSuccess, loading } from '../../../../utils/action.util';
import { API_LOGIN } from '../../../../api/constants';
import { PRINT } from '../../../../utils';

export const LOGIN_LOADING = 'LOGIN_LOADING';
export const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS';

export function actionLogin(params) {
        return (dispatch, getState) => Api.post(API_LOGIN, params).then((res) => {
            const data = res;
            PRINT('VENUELOGIN ACTION RESPONSE : ', data);
            dispatch(fetchSuccess(ACTION_LOGIN_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(LOGIN_LOADING, { loading: false, data }));
        });
    }

