/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import LoginReducer from '../routes/app/public/login/reducer';
import EventSearchReducer from "../routes/app/auth/events/list/reducer";
import TicketPurchaseListReducer from '../routes/app/auth/scan/list/reducer';
import CustomerDetailReducer from "../routes/app/auth/customer/deatil/reducer";
import TickeScanVerifyReducer from "../routes/app/auth/scan/scan_qr/reducer";


export default (history) =>
        combineReducers({
                router: connectRouter(history),
                LoginReducer,
                EventSearchReducer,
                TicketPurchaseListReducer,
                CustomerDetailReducer,
                TickeScanVerifyReducer
        });
