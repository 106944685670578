import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import Root from './routes';
import './index.css';

const App = (props) => {
  const { store, history } = props;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
          <Route path="/" component={Root} />
      </ConnectedRouter>
    </Provider>
  )
}

export default App