import {
    TICKET_PURCHASE_LIST_SUCCESS,
    TICKET_VERIFY_SUCCESS
} from './action';
import createReducer from '../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const TicketPurchaseListReducer = createReducer(
    INITIAL_STATE,
    {

        [TICKET_PURCHASE_LIST_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: TICKET_PURCHASE_LIST_SUCCESS,
                loading: false
            });
        },
        [TICKET_VERIFY_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: TICKET_VERIFY_SUCCESS,
                loading: false
            });
        }
    }
);
export default TicketPurchaseListReducer;