import React, { useState, useEffect } from "react";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCustomerDetail, CUSTOMER_DETAIL_SUCCESS } from './action';
import $ from 'jquery';
import { clearData } from "../../../../../utils/preferences";

const CustomerDetail = (props) => {

    const [customer, setCustomer] = useState(null)

    const initialRender = () => {
        $("body").addClass("bg-background");

        props.actionCustomerDetail({
            id: props.location.state.id
        })

    };
    useEffect(initialRender, []);

    const onEventsTicketPurchaseUpdate = () => {

        const { CustomerTicketPurchaseDetail } = props;

        if (CustomerTicketPurchaseDetail != null) {
            var TicketPurchaseCustomerDetailResponse = Object.assign({}, CustomerTicketPurchaseDetail);
            if (TicketPurchaseCustomerDetailResponse.result === true) {
                setCustomer(TicketPurchaseCustomerDetailResponse.response)
            } else {
                switch (TicketPurchaseCustomerDetailResponse.status) {
                    case 400:
                        clearData();
                        props.auth.updateToken(null);
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }


    useEffect(onEventsTicketPurchaseUpdate, [props.CustomerTicketPurchaseDetail]);


    return (
        <>

            <div className="container login-container">
                <section id="basic-form-layouts">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-color mb-2">Ticket Purchase Details</h4>
                            <div className="card box-container">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Venue Title</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.venue_title : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Event Title</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.event_title : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Ticket Title</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.ticket_title : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Purchaser Name</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.purchaser_name : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Event Ticket Amount</p>
                                                <p class="font-medium-1 text-bold-400 text-color">${customer != null ? customer.event_ticket_amount : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Quantity</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.quantity : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Total Amount</p>
                                                <p class="font-medium-1 text-bold-400 text-color">${customer != null ? customer.total_amount : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Phone Number</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.purchaser_phone_number : ''}</p>

                                            </div>
                                            <div className="col-12 col-md-6">
                                                <p class="text-muted font-medium-1 text-bold-600 text-color mb-0">Ticket Purchase Date</p>
                                                <p class="font-medium-1 text-bold-400 text-color">{customer != null ? customer.purchase_date : ''}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

function mapStateToProps(state) {

    var CustomerTicketPurchaseDetail = null;

    if (
        state.CustomerDetailReducer != null &&
        Object.keys(state.CustomerDetailReducer).length > 0 &&
        state.CustomerDetailReducer.constructor === Object
    ) {
        switch (state.CustomerDetailReducer.tag) {
            case CUSTOMER_DETAIL_SUCCESS:
                CustomerTicketPurchaseDetail = Object.assign({}, state.CustomerDetailReducer.data);
                delete state.CustomerDetailReducer;
                return { CustomerTicketPurchaseDetail };
            default:
                return { CustomerTicketPurchaseDetail }
        }
    }
    return { CustomerTicketPurchaseDetail }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionCustomerDetail }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CustomerDetail));