import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';
import { API_TICKET_PURCHASE_DETAIL } from '../../../../../api/constants';
import { PRINT } from '../../../../../utils';

export const CUSTOMER_DETAIL_LOADING = 'CUSTOMER_DETAIL_LOADING';
export const CUSTOMER_DETAIL_SUCCESS = 'CUSTOMER_DETAIL_SUCCESS';


export function actionCustomerDetail(params) {
    return (dispatch, getState) => Api.post(API_TICKET_PURCHASE_DETAIL,params).then((res) => {
        const data = res;
        PRINT('TICKET PIURCHASE ACTION RESPONSE : ', data);
        dispatch(fetchSuccess(CUSTOMER_DETAIL_SUCCESS, { data }));
    }).catch((reason) => {
        /** Handle Error */
        var data = {
            message: ''
        }
        dispatch(loading(CUSTOMER_DETAIL_LOADING, { loading: false, data }));
    });
}

