import {
    CUSTOMER_DETAIL_SUCCESS
} from './action';
import createReducer from '../../../../../reducers/createReducer';

const INITIAL_STATE = {
    data: [],
    loading: false
};

const CustomerDetailReducer = createReducer(
    INITIAL_STATE,
    {

        [CUSTOMER_DETAIL_SUCCESS](state, action) {
            return Object.assign({}, {
                ...state,
                data: action.payload.data,
                tag: CUSTOMER_DETAIL_SUCCESS,
                loading: false
            });
        }
    }
);
export default CustomerDetailReducer;