import React from 'react';

const ErrorLabel = (props) => {
    return (
        <label
            className="form-check-label font-small-2 text-danger"
            for="invalidCheck"
        >
            {props.message != null ? props.message : ''}
        </label>
    )
}
export default ErrorLabel