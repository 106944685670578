import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionTicketPurchaseList, TICKET_PURCHASE_LIST_SUCCESS } from './action';
import $ from 'jquery';
import { VscCalendar } from 'react-icons/vsc';
import { GoLocation } from 'react-icons/go';
import { FaTag } from 'react-icons/fa';
import { AiFillCheckCircle } from 'react-icons/ai';
// import Html5QrcodePlugin from "./Html5QrcodePlugin";
import { clearData, EVENTDATA, getData, setData } from "../../../../../utils/preferences";
import Swal from "sweetalert2";
import { PRIMARY_COLOR } from "../../../../../constants";
import dateFormat from 'dateformat';
import QrcodeScanner from '../scan_qr/Html5QrcodePlugin';


const ScanQRCode = (props) => {



    const [header, setHeader] = useState([
        {
            name: 'Customer'
        },
        {
            name: 'Ticket'
        },
        {
            name: 'Qty'
        },

    ])
    const [eventdate, setEventDate] = useState('')

    const [eventname, setEventName] = useState('');
    const [venuetitle, setVenueTitle] = useState('');

    const [ticketpurchaselist, setTicketPurchaseList] = useState([])

    const [renderQRScanner, setrenderQRScanner] = useState(false);


    const initialRender = () => {
        $("body").addClass("bg-background");

        props.actionTicketPurchaseList({
            venue_id: props.location.state.venue_id,
            event_id: props.location.state.event_id
        })
        setEventDate(props.location.state.eventdate)

    };
    useEffect(initialRender, []);


    const onEventsTicketPurchaseUpdate = () => {

        const { TicketPurchaseList } = props;

        if (TicketPurchaseList != null) {
            var TicketPurchaseListResponse = Object.assign({}, TicketPurchaseList);
            if (TicketPurchaseListResponse.result === true) {
                setTicketPurchaseList(TicketPurchaseListResponse.response.ticket_purchase_list);
                setEventName(TicketPurchaseListResponse.response.event_title);
                setVenueTitle(TicketPurchaseListResponse.response.venue_title)
                setData(EVENTDATA, JSON.stringify(TicketPurchaseListResponse.response));
            } else {
                setTicketPurchaseList([])
                switch (TicketPurchaseListResponse.status) {
                    case 400:
                        clearData();
                        props.auth.updateToken(null);
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }


    useEffect(onEventsTicketPurchaseUpdate, [props.TicketPurchaseList]);



    // const onNewScanResult = (data) => {

    //     console.log("App [result]", JSON.stringify(data));


    //     Swal.fire({
    //         title: "QR Code Scan",
    //         text: `${data})`,
    //         icon: "success",
    //         // confirmButtonText: `Yes`,
    //         // denyButtonText: `No`,
    //         // showDenyButton: true,
    //         confirmButtonColor: PRIMARY_COLOR
    //     }).then((success) => {
    //         if (success.isConfirmed) {
    //             props.history.push({
    //                 pathname: `/scan-qr-code-result`, state: {
    //                     result: data.text,
    //                     date: props.location.state.eventdate
    //                 }
    //             });

    //         }
    //     });
    //     // console.log("decodedText", decodedText);
    //     // props.history.push({ pathname: `/scan-qr-code-result` });
    //     // let decodedResults = this.state.decodedResults;
    //     // decodedResults.push(decodedResult);
    //     // this.setState((state, props) => {
    //     //   state.decodedResults.push(decodedResult);
    //     //   return state;
    //     // });
    // }

    return (
        <>
            <div className="container login-container">
                <section id="basic-form-layouts" style={{ padding: '1.5rem' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card box-container">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-2">
                                                <VscCalendar color="#051fde" size={20} />
                                            </div>
                                            <div className="col-10">
                                                <p class="font-medium-2 text-color" >{dateFormat(eventdate, 'dd mmmm yyyy')}</p>
                                            </div>
                                        </div>
                                        {
                                            venuetitle != '' ?
                                                <div className="row">
                                                    <div className="col-2">
                                                        <GoLocation color="#051fde" size={20} />
                                                    </div>
                                                    <div className="col-10">
                                                        <p class="font-medium-2 text-color" >{venuetitle}</p>
                                                    </div>
                                                </div>
                                                : ''

                                        }

                                        <div className="row">
                                            <div className="col-2">
                                                <FaTag color="#051fde" size={20} />
                                            </div>
                                            <div className="col-10">
                                                <p class="font-medium-2 text-color" >{eventname}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-row justify-content-center align-items-center mb-3">
                                <div className="col-12 col-md-12" style={{ paddingRight: 0, paddingLeft: 0 }}>
                                    {
                                        renderQRScanner ?
                                            <QrcodeScanner
                                                {...props}

                                                venuetitle={venuetitle}
                                                eventname={eventname}
                                            // handleScan={onNewScanResult}
                                            // handleError={onScannerError}
                                            />
                                            : <></>
                                    }
                                    {/* <Html5QrcodePlugin
                                        qrCodeSuccessCallback={onNewScanResult} /> */}
                                    {
                                        !renderQRScanner ?
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-block rounded"
                                                onClick={() => setrenderQRScanner(true)}
                                            >
                                                Scan QR
                                            </button>
                                            : <></>
                                    }
                                </div>
                            </div>

                            {
                                ticketpurchaselist != null && ticketpurchaselist.length > 0 ?
                                    <>
                                        <div className="row border-bottom mb-1">
                                            {
                                                header.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className={`${index === 0 ? 'col-4' : 'col-4'}`} style={{ textAlign: 'center' }}>
                                                                <p className="text-bold-600 text-color"> {item.name}</p>
                                                            </div>

                                                        </>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className="row">
                                            {
                                                ticketpurchaselist.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className="col-4">
                                                                <p className="text-color" style={{ textDecorationLine: 'underline', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                                    onClick={() => {
                                                                        props.history.push({ pathname: `/customer-details`, state: { id: item.id } })
                                                                    }}> {item.purchaser_name}</p>
                                                            </div>
                                                            <div className="col-4" style={{ textAlign: 'center' }}>
                                                                <p
                                                                    className="text-color"
                                                                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}

                                                                > {item.ticket_title}</p>
                                                            </div>
                                                            <div className="col-4" style={{ textAlign: 'center' }}>
                                                                <p className="text-color"> {item.quantity} {item.is_redeem === "Y" ? <AiFillCheckCircle color="green" size={14} /> : ''}</p>

                                                            </div>
                                                        </>

                                                    )
                                                })
                                            }
                                        </div>
                                    </>

                                    :
                                    ''

                            }

                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

function mapStateToProps(state) {

    var TicketPurchaseList = null;

    if (
        state.TicketPurchaseListReducer != null &&
        Object.keys(state.TicketPurchaseListReducer).length > 0 &&
        state.TicketPurchaseListReducer.constructor === Object
    ) {
        switch (state.TicketPurchaseListReducer.tag) {
            case TICKET_PURCHASE_LIST_SUCCESS:
                TicketPurchaseList = Object.assign({}, state.TicketPurchaseListReducer.data);
                delete state.TicketPurchaseListReducer;
                return { TicketPurchaseList };
            default:
                return { TicketPurchaseList }
        }
    }
    return { TicketPurchaseList }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ actionTicketPurchaseList }, dispatch);
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ScanQRCode));
