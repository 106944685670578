import React from "react";
import { withAuth } from "react-auth-guard";
import Content from "../../../component/content";
// import Footer from "../../../component/footer";
import Header from "../../../component/header";

const AuthRoute = (props) => {
    const { location } = props;

    var arrPaths = ['/', '/login']

    if (arrPaths.includes(location.pathname)) {
        window.location.replace("/events");
    }
    return (
        <>
            <Header {...props}/>
            <Content {...props}/>
            {/* <Footer {...props}/> */}
        </>
    );
};
export default withAuth(AuthRoute);
